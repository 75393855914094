<template>
	<display
	display="table"
	model_name="payment_method"
	:show_btn_edit="false"
	:properties="properties"
	:models="models"></display>	
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		Display: () => import('@/common-vue/components/display/Index')
	},
	computed: {
		orders() {
			return this.$store.state.order.models 
		},
		payment_methods() {
			return this.$store.state.payment_method.models 
		},
		models() {
			let models = []
			let index = 1
			this.payment_methods.forEach(payment_method => {
				models.push({
					...payment_method,
					total_orders: this.getOrderFromPaymentMethod(payment_method).length,
					total: this.getTotalPaymentMethod(payment_method),
				})
			})
			return models 
		},
		properties() {
			return [
				{
					text: 'Metodo de pago',
					key: 'name',
					show: true,
				},
				{
					text: 'Cant pedidos',
					key: 'total_orders',
					show: true,
				},
				{
					text: 'Total vendido',
					key: 'total',
					show: true,
				},
			]
		}
	},
	methods: {
		getOrderFromPaymentMethod(payment_method) {
			let orders = this.orders.filter(order => {
				return order.payment_method_id == payment_method.id 
			})
			return orders
		},
		getTotalPaymentMethod(payment_method) {
			let orders = this.getOrderFromPaymentMethod(payment_method)
			let total = 0 
			orders.forEach(order => {
				total += this.getTotalProducts(order.products, order.discount)
			})
			return this.price(total) 
		},
	}
}
</script>